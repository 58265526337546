<template>
 <footer>
  <div>
   <a href="mailto:helyindex@protonmail.com">
    <span v-if='lang == "en"'>contact</span>
    <span v-else>kontakt</span>
   </a>
  </div>
  <div>
   <a href="https://api.fureweb.com/spreadsheets/1_4FO0S3DEiqmeCZZ66vvzU-dEcCJERGDtzgoMYexAk4">api</a>
  </div>
 </footer>
</template>

<script>
 export default {
  props: ['lang']
 }
</script>

<style>
 .nav-link {
  padding-left: 20px;
 }
 footer {
  height: 20px;
  width: calc(100% - 20px);
  margin: 20px 10px;
  display: flex;
  justify-content: space-between;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
 }
 @media only screen and (min-width: 1024px) {
  footer {
   margin: 20px;
   width: calc(100% - 40px);
  }
 }
</style>
