<template>
 <header>
  <div class="title">
   <h1><a href="/">hely[i]</a></h1>
  </div>
  <nav>
   <div class="nav-link"><router-link :to="{ name: 'home' }">
    <span v-if="lang == 'en'">list</span>
    <span v-else>lista</span>
   </router-link></div>
   <div class="nav-link"><router-link :to="{ name: 'map' }">
    <span v-if="lang == 'en'">map</span>
    <span v-else>térkép</span>
   </router-link></div>
   <div class="nav-link"><router-link :to="{ name: 'about'}">
    <span v-if="lang == 'en'">about</span>
    <span v-else>rólunk</span>
   </router-link></div>
   <div class="nav-link">
    <span class="btn" @click="$emit('langchange')" v-if="lang == 'en'">hu</span>
    <span class="btn" @click="$emit('langchange')" v-else>en</span>
   </div>
  </nav>
 </header>
</template>

<script>
 // import { onMounted } from 'vue'
 // import { ref } from 'vue'
 export default {
  props: ['lang']
 }
</script>

<style>
 header { 
  height: 20px;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  position: sticky;
  top: 0;
  background: rgba(197,205,173, 1);
  z-index: 10;
}
nav {
 display: flex;
 justify-content: flex-end;
}
.nav-link {
 padding-left: 20px;
}
.btn {
 cursor: pointer;
}
@media only screen and (min-width: 1024px) {
 header {
  padding: 20px 20px;
 }
}
</style>
