<template>
	<!-- <div v-if="!data.length" class="loading">Loading...</div> -->
	<div>
		<div class="container">
			<div v-for="i in data" :key="i" class="item">
				<div class="item-wrapper"><a :href="i.Website"><span class="item-name" v-if='lang == "en"'>{{ i.NameEN }}</span><span class="item-name" v-else>{{ i.Name }}</span></a><span class="small-line"></span><span class="line"></span></div>
				<div class="item-wrapper"><span class="item-website"><a :href="i.Website" target="_blank" v-if="i.PrettyUrl"><img class="arrow" src="data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTQuNTIzIDE4Ljc4N3M0LjUwMS00LjUwNSA2LjI1NS02LjI2Yy4xNDYtLjE0Ni4yMTktLjMzOC4yMTktLjUzcy0uMDczLS4zODMtLjIxOS0uNTNjLTEuNzUzLTEuNzU0LTYuMjU1LTYuMjU4LTYuMjU1LTYuMjU4LS4xNDQtLjE0NS0uMzM0LS4yMTctLjUyNC0uMjE3LS4xOTMgMC0uMzg1LjA3NC0uNTMyLjIyMS0uMjkzLjI5Mi0uMjk1Ljc2Ni0uMDA0IDEuMDU2bDQuOTc4IDQuOTc4aC0xNC42OTJjLS40MTQgMC0uNzUuMzM2LS43NS43NXMuMzM2Ljc1Ljc1Ljc1aDE0LjY5MmwtNC45NzkgNC45NzljLS4yODkuMjg5LS4yODYuNzYyLjAwNiAxLjA1NC4xNDguMTQ4LjM0MS4yMjIuNTMzLjIyMi4xOSAwIC4zNzgtLjA3Mi41MjItLjIxNXoiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg=="> {{ i.PrettyUrl }}</a></span><span class="line"></span></div>
				<div class="item-wrapper"><span class="item-address"><router-link :to="'/map#' + i.Hash">{{ i.Address }}</router-link></span><span class="line"></span></div>
				<div class="item-wrapper cty"><span class="item-city">
					<router-link :to="'/map#' + i.Hash" v-if="i.LatLng">{{ i.City }}</router-link>
					<span v-else>{{ i.City }}</span>
				</span></div>
				<!-- {{ i.Tags }} -->
			</div>
		</div>
	</div>
	<Footer :lang='lang'/>
</template>

<script>
	import { ref } from 'vue'
	import Footer from '../components/Footer.vue'
	export default {
		props: ['data', 'lang'],
		components: {Footer}
	}
</script>

<style>
	.arrow {
		display: none;
		height: 15px;
		rotate: -45deg;
/*		margin-bottom: -3px;*/
	}
	.item {
		margin: 20px 0;
		max-width: 100%;
	}
	.item span {
		display: block;
	}
	.item-website, .item-address, .item-city {
		font-size: 18px;
	}
	.line {
		display: none;
	}
	.small-line {
		background-color: #ccff00;
		height: 3px;
		width: 30px;
	}
	@media only screen and (min-width: 1200px) {
		.items {
			margin: 0 20px;
		}
		.item {
			margin: 5px 0;
			font-size: 20px;
			display: flex;
			justify-content: start;
			align-items: flex-end;
			/* background: #ddd; */
		}
		.item-wrapper {
			width: 35%;
			display: flex;
			align-items: flex-end;
/*			column-gap: 20px;*/
		}
		/*.item-website,
		.item-address {
			margin-right: 10px;
			margin-left: 10px;
		}
		.item-name {
			margin-right: 10px;
		}
		.item-city {
			margin-left: 10px;
		}*/
		.item span {
			font-size: 20px;
		}
		.cty {
			flex-shrink: 3;
		}
		.item .small-line {
			display: none;
		}
		.line {
			background-color: #ccff00;
			/*background: linear-gradient(to left, purple, mediumpurple);*/
/*			margin-right: 20px;*/
			height: 3px;
			flex-grow: 2;
			margin-bottom: 3.75px;
		}
	}
</style>