<template>
 <Nav :lang="lang" @langchange='setLang'/>
 <div v-if="!data.length" class="loading">Loading...</div>
 <router-view v-else :data="data" :lang="lang"/>
</template>

<script>
 import Nav from './components/Nav.vue'
 import { ref, onMounted } from 'vue'
 export default {
  components: { Nav },

  setup() {
   const data = ref([])
   const lang = ref("hu")
   const sheetId = "1_4FO0S3DEiqmeCZZ66vvzU-dEcCJERGDtzgoMYexAk4"
   const subSheet = "Spaces"
   const parser = new PublicGoogleSheetsParser()

   const load = async () => {
    data.value = await parser.parse(sheetId, subSheet)
    for (var i = data.value.length - 1; i >= 0; i--) {
      if (data.value[i].Website) {
        let prettyurl
        
        if (data.value[i].WebsitePretty) {
          // manual pretty
          data.value[i].PrettyUrl = data.value[i].WebsitePretty
        } else {
          // auto pretty
          prettyurl = data.value[i].Website.split("//").pop()
          prettyurl = prettyurl.split("www.").pop()
          prettyurl = prettyurl.replace("instagram.com/", "@")
          prettyurl = prettyurl.replace("facebook.com/", "@")
          data.value[i].PrettyUrl = prettyurl.split("/")[0]
        }
      
      } else {
        data.value[i].PrettyUrl = false
      }
     let hash = data.value[i].NameEN.normalize("NFD").replace(/[\u0300-\u036f;\s!@#$%^&*+-.]/g, "")
     hash = hash.toLowerCase()
     data.value[i].Hash = hash
    }
   }

   load()

   function setLang() {
    lang.value == 'en' ? lang.value = 'hu' : lang.value = 'en'
   }

   return { data, lang, setLang }
  }
 }
</script>

<style>
 @font-face {
  font-family: Inter;
  src: url(assets/Inter-Regular.woff2);
 }
 :root {
  --bg: #c5cdad;
  --txt: #000;
  --accent: #ccff00;
 }
 body {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  background-color: var(--bg);
  color: var(--txt);
  margin: 0;
 }
 a {
  text-decoration: none;
  color: var(--txt);
 }
 .router-link-exact-active {
  text-decoration: underline;
/*  text-decoration-thickness: 3px;*/
 }
 h1 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
 }
 .loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
 }
 li {
  list-style: none;
  display: inline-block;
 }
 ul {
  margin: 0;
  padding: 0;
 }
 .bold {
  font-weight: 600;
 }
 .container {
  margin: 20px 10px;
  min-height: calc(100vh - 120px);
 }
 @media only screen and (min-width: 1024px) {
  .container {
   margin: 0 20px;
  }
  .left {
   width: 35vw;
  }
 }
 ::-webkit-scrollbar {
   width: 10px;
 }
 ::-webkit-scrollbar-track {
   background: none;
 }
 ::-webkit-scrollbar-thumb {
   background-color: var(--accent);
 }
 ::-webkit-scrollbar-thumb:hover {
   background: #ddd;
 }
 ::-moz-selection {
   background: #ddd;
 }
 ::selection {
   background: #ddd;
 }
</style>
